import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';

// Generate Order Data
function createData(id: number, device: string, fstype:string, mountPoint:string, usage:number) {
  return { id, device, fstype, mountPoint, usage };
}

const rows = [
  createData(0, '/dev/sda1', 'ext4', '/', 30),
  createData(1, '/dev/sdb1', 'ext4', '/', 80),
];

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function DiskUsageTable() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Title>Disk Usage</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Device</TableCell>
            <TableCell>FS Type</TableCell>
            <TableCell>Mount Point</TableCell>
            <TableCell>Usage</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.device}</TableCell>
              <TableCell>{row.fstype}</TableCell>
              <TableCell>{row.mountPoint}</TableCell>
              <TableCell>{row.usage}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>      
    </React.Fragment>
  );
}