import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';

// Generate Order Data
function createData(id: number, ip: string, numWorkes:number, freeCount:number) {
  return { id, ip, numWorkes, freeCount };
}

const rows = [
  createData(0, '192.168.0.100', 10, 10),
  createData(1, '192.168.0.101', 20, 20)
];

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function SttWorkerTable() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Title>Connected Worker</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>IP</TableCell>
            <TableCell>#Workers</TableCell>
            <TableCell>Free Workers</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.ip}</TableCell>
              <TableCell>{row.numWorkes}</TableCell>
              <TableCell>{row.freeCount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>      
    </React.Fragment>
  );
}