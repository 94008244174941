import { Container, Grid, Link, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from "reactn";
import { CONTACT_EMAIL } from '../../constants/misc.constants';
import { I18nContext } from '../../hooks/i18n/I18nContext';
import { ICONS } from '../../theme/icons';
import { IMAGES } from '../../theme/images';
import { CustomTheme } from '../../theme/index';
import { setPageTitle } from '../../util/misc';
import { Copyright } from './Copyright';
import SttWorkerChart from './SttWorkerChart';
import Paper from '@material-ui/core/Paper';
import SttWorkerTable from './SttWorkerTable';
import Box from '@material-ui/core/Box';
import MemoryChart from './MemoryChart';
import CpuChart from './CpuChart';
import DiskUsageTable from './DiskUsageTable';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { number } from 'yup';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    header: {
      marginTop: theme.spacing(5),
      margin: theme.spacing(4),
    },
    headerText: {
      fontFamily: 'Muli',
      fontWeight: 'bold',
      color: theme.header.lightBlue,
    },
    text: {
      fontFamily: 'NotoSansKR',
      fontWeight: 'bold',
    },
    textBlock: {
      maxWidth: 300,
    },
    textBlockBottomMargin: {
      marginBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

export function Home() {
  const { translate } = React.useContext(I18nContext);
  const classes = useStyles();
  const [timeRange, setTimeRange] = React.useState(30);
  const [refreshInterval, setRefreshInterval] = React.useState(30);

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  React.useEffect(() => {
    setPageTitle(translate('common.haiv'));
  }, []);

  const changeTimeRange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value: number = parseInt(event.target.value as string);
    setTimeRange(value);
  };

  const changeRefreshInterval = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value: number = parseInt(event.target.value as string);
    setRefreshInterval(value);
  };

  return (
    <Container maxWidth="xl">    
      <Grid container>
        <Grid item>
          <FormControl className={classes.formControl}>
            <InputLabel id="select-time-range">Time range</InputLabel>
            <Select
              labelId="select-time-range"
              id="time-range-select"
              value={timeRange}
              onChange={changeTimeRange}              
            >
              <MenuItem value={5}>Last 5 minutes</MenuItem>
              <MenuItem value={15}>Last 15 minutes</MenuItem>
              <MenuItem value={30}>Last 30 minutes</MenuItem>
              <MenuItem value={60}>Last 1 hour</MenuItem>
              <MenuItem value={180}>Last 3 hour</MenuItem>
              <MenuItem value={360}>Last 6 hour</MenuItem>
              <MenuItem value={540}>Last 9 hour</MenuItem>
              <MenuItem value={720}>Last 12 hour</MenuItem>
              <MenuItem value={1440}>Last 24 hour</MenuItem>
              <MenuItem value={2880}>Last 2 days</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="select-refresh">Time range</InputLabel>
            <Select
              labelId="select-refresh"
              id="refresh-select"
              value={refreshInterval}
              onChange={changeRefreshInterval}
            >
              <MenuItem value={0}>Off</MenuItem>
              <MenuItem value={10}>10 sec</MenuItem>
              <MenuItem value={30}>30 sec</MenuItem>
              <MenuItem value={60}>1 min</MenuItem>
              <MenuItem value={200}>5 min</MenuItem>
              <MenuItem value={900}>15 min</MenuItem>
              <MenuItem value={1800}>30 min</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    
      <Grid
        container
        // direction='column'
        // justify='center'
        alignItems='center'
        alignContent='center'
        spacing={2}
      >
        {/* Chart */}
        <Grid item xs={12} md={8}>
          <Paper className={fixedHeightPaper}>
            <SttWorkerChart />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={fixedHeightPaper}>
            <SttWorkerTable />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={fixedHeightPaper}>
            <CpuChart />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={fixedHeightPaper}>
            <MemoryChart />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={fixedHeightPaper}>
            <DiskUsageTable />
          </Paper>
        </Grid>        
        {/* Recent Deposits */}
        {/* <Grid item xs={12} md={4} lg={3}>
          <Paper>
            <Deposits />
          </Paper>
        </Grid> */}
        
        {/* Recent Orders
        <Grid item xs={12}>
          <Paper>
            <Orders />
          </Paper>
        </Grid>         */}
      </Grid>      
      <Box pt={4}>
        <Copyright />
      </Box>
    </Container>
  );
}
